import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SvgIcon } from '../../common';
import { connect } from 'react-redux';
import { authActions } from '../../../actions'
import axios from 'axios';
import { BASE_URL } from '../../../config/config'
import './signup.scss';
import { bindActionCreators } from 'redux';
import { Label, Checkbox, Message, Button } from 'semantic-ui-react';
import SignUpComplete from './SignUpComplete';
import { emailRegex, generalMailServer } from '../../../common/constants';
import SEO from '../../seo/SEO';


const signUpStatusEnum = {
    UNDEFINED: "UNDEFINED",
    IN_PROGRESS: "IN_PROGRESS",
    DONE: "DONE"
}

class SignUpView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            errors: [],
            business_email_error: false,
            signUpStatus: signUpStatusEnum.UNDEFINED,
            showPassword: false
        };
    }

    componentDidMount() {
        if (this.props.location.search != undefined && this.props.location.search != '') {
            var email_string = (this.props.location.search.indexOf("=") !== -1) ? this.props.location.search.split('=')[1] : '';
            this.setState({ formData: { email: email_string } });
            console.log(this.state)
        }
    }

    inputChangeHandler = (e) => {
        const { name, value } = e.target;
        this.setState({ formData: { ...this.state.formData, [name]: value } });
        this.updateErrors(name, value);
    }

    handleCountrySelect = (e, { value }) => {
        this.setState({ formData: { ...this.state.formData, country: value } })
        this.updateErrors("country", value);
    }

    registerUser = async () => {
        const validationResult = this.validateForm();
        if (!validationResult) return;

        this.setState({
            signUpStatus: signUpStatusEnum.IN_PROGRESS,
            errors: [],
            validationErrorMessage: ''
        });

        axios.post(`${BASE_URL}/api/v2/registration/user`, { ...this.state.formData }, { withCredentials: true })
            .then((response) => {
                if (response.data.success) {
                    this.setState({ signUpStatus: signUpStatusEnum.DONE, userId: response.data.data.user_id });
                    localStorage.setItem('userId', response.data.data.user_id);
                    localStorage.setItem('email', this.state.formData.email);
                    this.props.history.push('/verify-otp');
                }
                else {
                    this.setState({ signUpStatus: signUpStatusEnum.UNDEFINED, validationErrorMessage: response.data.messages[0].message });
                }
            })
            .catch(error =>
                this.setState({
                    validationErrorMessage: error.response.data.error,
                    signUpStatus: signUpStatusEnum.UNDEFINED
                })
            )
    }

    validateForm = () => {
        const { formData } = this.state;
        const { email, password, confirmPassword } = formData;

        const reqFields = ["firstName", "lastName", "email", "password", "confirmPassword"];
        const filledFields = Object.keys(formData);

        let difference = (reqFields).filter(x => !filledFields.includes(x));

        if (difference.length > 0) {
            this.setState({
                errors: [...this.state.errors, ...difference],
                validationErrorMessage: "Please fill all the required fields "
            });
            return false;
        }
        if (password !== confirmPassword) {
            this.setState({ validationErrorMessage: "password and confirm password are different" })
            return false;
        }
        if (password.length < 8) {
            this.setState({ validationErrorMessage: "password must be atleast 8 characters long" });
            return false;
        }
        if (!emailRegex.test(email.trim())) {
            this.setState({ validationErrorMessage: "invalid email id" })
            return false;
        }
        
        if (!isCompanyEmail(email)) {
            this.setState({ validationErrorMessage: "Please use business email to login /register." })
            return false;
        }
        return true;
    }

    updateErrors(name, value) {
        let business_email = null;
        let errors = [...this.state.errors];
        if (!value) {
            errors.push(name);
        }
        else {
            const index = errors.indexOf(name);
            if (index > -1) {
                errors.splice(index, 1);
            }

            if (name == 'email') {
                let is_email_general = generalMailServer.some(mail_server => value.includes(mail_server));
                if (is_email_general) {
                    business_email = true
                } else {
                    business_email = false
                }
            }
        }
        if (business_email !== null) {
            this.setState({ errors, business_email_error: business_email });
        } else {
            this.setState({ errors });
        }
    }

    handleSearchChange = (e, { value }) => {
        if (!value) {
            this.setState({ formData: { ...this.state.formData, company: '', companyId: '', country: '' }, disableCountrySelect: false })
        }
        this.setState({ formData: { ...this.state.formData, company: value } });
        if (value.length > 4) {
            this.setState({ isLoading: true })
            axios.get(`${BASE_URL}/api/v1/company/search/${value}`, { withCredentials: true })
                .then(response => {
                    this.setState({
                        isLoading: false,
                        results: response.data
                    })
                })
        }
    }

    handleResultSelect = (e, data) => {
        const { name, id, country } = data.result._source;
        this.setState({ value: data.result._source.name })
        this.setState({ formData: { ...this.state.formData, company: name, companyId: id, country: country }, disableCountrySelect: true })

        let errors = [...this.state.errors];

        let index = errors.indexOf("company");
        errors.splice(index, 1);
        index = errors.indexOf("country");
        errors.splice(index, 1);
        this.setState({ errors });
    }

    resultRenderer = result => {
        if (result && result._source && result._source.id)
            return (
                <div key={result._source.id}>
                    <Label content={result._source.name} />
                    <Label style={{ float: 'right' }} content={result._source.country} />
                </div>
            );
    };
    render() {
        const { results, isLoading, errors, formData, signUpStatus, validationErrorMessage, business_email_error } = this.state;
        var email = (formData.email != undefined && formData.email != null) ? formData.email : '';
        return (
            <React.Fragment>
                <SEO robots="index, follow"
                    canonicalUrl="/signup"
                />
                <div className="signup-wrapper">
                    <div className="signup-left">
                        <div className="signupleft-inner">
                            <SvgIcon name="logo" viewbox="0 0 907 93" />
                            <h2>Map Your Product Community And Stay Connected. Anytime, Anywhere.</h2>
                        </div>
                    </div>
                    <div className="signup-right">
                        {
                            this.state.signUpStatus === signUpStatusEnum.DONE ?
                                <SignUpComplete />
                                :

                                <div className="signupright-inner">
                                    <div className="mb-4 title">
                                        <h3>We are glad you are interested in <br /> joining your product community!</h3>
                                        <p>Create an account to continue to Mappes.io.</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="ui input large w-100 form-input mb-3">
                                                <input type="text" placeholder="First name*" name="firstName" onChange={this.inputChangeHandler} className={errors.includes("firstName") && 'error'} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="ui input large w-100 form-input mb-3">
                                                <input type="text" placeholder="Last name*" name="lastName" onChange={this.inputChangeHandler} className={errors.includes("lastName") && 'error'} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="ui input large w-100 form-input mb-3">
                                                <input type="text" placeholder="Business Email Address*" name="email" value={email} onChange={this.inputChangeHandler} className={errors.includes("email") && 'error'} />
                                            </div>
                                            {business_email_error ? (
                                                <p className='yellow_warning text-yellow'>Please use the official e-mail id to get the better and personalised experience</p>) :
                                                (<p className='emailinfo-text'>Use your business email ID to get more companies & products information</p>)
                                            }

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="ui input large w-100 form-input mb-3">
                                                <input type={this.state.showPassword ? 'text' : 'password'} placeholder="Password*" name="password" onChange={this.inputChangeHandler} className={errors.includes("password") && 'error'} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="ui input large w-100 form-input">
                                                <input type={this.state.showPassword ? 'text' : 'password'} placeholder="Confirm Password*" name="confirmPassword" onChange={this.inputChangeHandler} className={errors.includes("confirmPassword") && 'error'} />
                                            </div>
                                        </div>
                                        <div className='col-sm-12'>
                                            <Checkbox onClick={(e, data) => this.setState({ showPassword: data.checked })} label='Show password' />
                                        </div>
                                    </div>

                                    <div className="row mt-5">
                                        <div className="col-sm-12">
                                            {validationErrorMessage &&
                                                <Message warning size="mini">
                                                    <Message.Header>{validationErrorMessage}</Message.Header>
                                                </Message>
                                            }
                                            <p className="fs-12 by-clickingtext">By continuing, you agree to Mappes Terms of Service, Privacy Policy</p>
                                            <Button className="ui primary button w-100 btn-lg custom-btn" onClick={this.registerUser} loading={signUpStatus === signUpStatusEnum.IN_PROGRESS}>
                                                CREATE ACCOUNT
                                            </Button>
                                            <div className="text-center mt-3">
                                                <b>Already have an Account? <Link to="/">Login</Link></b>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                        }
                    </div>
                </div >
            </React.Fragment >
        );
    }
}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators(authActions, dispatch) }
}
function mapStateToProps({ auth }) {
    return { auth };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpView);
