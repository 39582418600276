import {
    CREATE_EVENT, EVENT_DETAILS, EVENT_LIST, EXHIBITORS_COLLECTION,
    ATTENDEES, RESET_EVENT, EMPLOYEES_COLLECTION, EVENT_DELETED, RESET_EXHIBITORS_COLLECTION, VOTES, RESET_ATTENDEES_COLLECTION, INDUSTRY_EVENT_LIST, SET_EVENT_PAGINATION, ALL_EVENTS, RSVP_EVENTS, HALLBOOTHSAVEFAIL, HALLBOOTHSAVESUCCESS, EXIBITOR_FILTER_PRODUCT_LIST, EXIBITORFILTERSUCCESS, EXIBITORSEARCHSUCCESS, BOOKMARKSAVESUCCESS, BOOKMARKSAVEFAIL
} from '../../config/types'

export default function (state = {
    eventPagination: {
        start: 1,
        size: 50
    },
}, action) {
    switch (action.type) {
        case RESET_EVENT:
            return { ...state, eventDetails: null, exhibitorsCollection: null, attendees: null, eventCreated: null, eventDeleted: null, allEventCollection: null }
        case CREATE_EVENT:
            return { ...state, eventCreated: action.payload }
        case EVENT_DETAILS:
            return { ...state, eventDetails: action.payload }
        case EVENT_LIST:
            return { ...state, eventsList: action.payload }
        case ALL_EVENTS:
            return { ...state, allEventCollection: updateEventCollection(state, action) }
        case EXHIBITORS_COLLECTION:
            return { ...state, exhibitorsCollection: updateExhibitorsCollection(state, action) }
        case ATTENDEES:
            return { ...state, attendees: action.payload }
        case EMPLOYEES_COLLECTION:
            return { ...state, employeesCollection: action.payload }
        case EVENT_DELETED:
            return { ...state, eventDeleted: action.payload }
        case VOTES:
            return { ...state, votes: action.payload }
        case RESET_EXHIBITORS_COLLECTION:
            return { ...state, exhibitorsCollection: null }
        case RESET_ATTENDEES_COLLECTION:
            return { ...state, attendees: null }
        case INDUSTRY_EVENT_LIST:
            return { ...state, industryEventsList: action.payload }
        case SET_EVENT_PAGINATION:
            return { ...state, eventPagination: action.payload }
        case RSVP_EVENTS:
            return { ...state, rsvp_list: action.payload }
        case HALLBOOTHSAVEFAIL:
            return { ...state, eventHallData: action.payload }
        case HALLBOOTHSAVESUCCESS:
            return { ...state, eventHallBoothSuccess: action.payload }
        case BOOKMARKSAVESUCCESS:
            return { ...state, eventBookmarkSuccess: action.payload }
        case BOOKMARKSAVEFAIL:
            return { ...state, eventBookmarkFail: action.payload }
        case EXIBITOR_FILTER_PRODUCT_LIST:
            return { ...state, exibitor_filter_prod: action.payload }
        case EXIBITORFILTERSUCCESS:
            return { ...state, exibitorfiltersuccess: action.payload }
        case EXIBITORSEARCHSUCCESS:
            return { ...state, exibitorsearchsuccess: action.payload }
        default:
            return state;
    }
}

const updateEventCollection = (state, action) => {
    var all_events = state.allEventCollection && state.allEventCollection ? [...state.allEventCollection] : [];
    if (state.eventPagination.start === 0 || all_events.length === 0) {
        return action.payload;
    }
    else {
        all_events.push(...action.payload);
        return all_events;
    }
}

const updateExhibitorsCollection = (state, action) => {
    const exhibitors = state.exhibitorsCollection ? [...state.exhibitorsCollection] : [];
    if (state.eventPagination.start === 0 || state.eventPagination.start === 1 || exhibitors.length === 0) {
        return action.payload;
    }
    else {
        exhibitors.push(...action.payload);
        return exhibitors;
    }
}