import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { eventActions, commonActions, getUserInfo, getUserProfileInfo } from '../../actions';
import { Grid, Container, List, Button, Tab, Table, Loader, Dimmer, Popup, Modal, Image, Menu, Label, Input, Checkbox, Message } from 'semantic-ui-react'
import { formatIsoMiliseconds, replaceSpaceInName, getDay } from '../../common/helperMethods';
import MediaQuery from 'react-responsive';
import Slider from "react-slick";
import Navbar from '../common/layout/navbar/Navbar'
import LeftSidebar from '../common/LeftSidebar'
import RightSection from '../common/RightSection'
import { eventVotes } from '../../common/constants';
import TagModal from './TagModal';
import src from '../../assets/images/add-event-default.png'
import SEO from '../seo/SEO';
import { apiStatus } from '../../common/constants';
import SharePost from '../networkFeed/SharePost';
import { Col, Row, SvgIcon } from '../common';
import LoginButton from '../common/LoginButton';
import ShowMoreLess from '../networkFeed/showMoreLess';
import MappesDropdown from '../common/MappesDropdown';
import './event-details.scss';
import './events.css';
import GuestUserLoginToast from '../common/GuestUserLoginAlert';

const filterOptionAttendees = [
    {
        key: 'Attending',
        text: 'Attending',
        value: 'Attending',
        label: { color: 'green', empty: true, circular: true },
        class: 'greenBackground'
    },
    {
        key: 'Interested',
        text: 'Interested',
        value: 'Interested',
        label: { color: 'blue', empty: true, circular: true },
        class: 'blueBackground'
    },
    {
        key: 'Not Sure',
        text: 'Not Sure',
        value: 'Not Sure',
        label: { color: 'yellow', empty: true, circular: true },
        class: 'greenBackground'
    },
    // {
    //     key: 'Relevant to Me',
    //     text: 'Relevant to Me',
    //     value: 'Relevant to Me',
    //     label: { color: 'pink', empty: true, circular: true },
    //     class: 'pinkBackground'
    // },
    {
        key: 'Show All',
        text: 'Show All',
        value: 'Show All',
        label: { color: 'grey', empty: true, circular: true },
        class: 'greyBackground'
    }
]

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <SvgIcon name='chevron-right' viewbox="0 0 10 16" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}>
            <SvgIcon name='chevron-left' viewbox="0 0 10 16" />
        </div>
    );
}

class Event extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            exhibitorFilter: "All",
            attendeeFilter: "Show All",
            src: src,
            sharePostPopup: false,
            loading: false,
            voting_loading: false,
            modalOpen: false,
            modalOpen1: false,
            modalOpen2: false,
            searchString: '',
            activeIndex: 0,
            bookmarkSave: false,
            interestsShow: false,
            HBformData: {
                hallnumber: '',
                boothnumber: '',
            },
            hallboothError: false,
            filter_prod: [],
            filterState: false,
            savedBookmark: "false",
            show_filter_loader: false,
            prodfiltersearchString: '',
            search_by_product: '',
            showLoginAlert: false,
            bookmarkError: false,

        }
    }

    componentDidMount() {
        const { getEvent, getVotes, getExhibitors, match, getExhibitorFilterProducts } = this.props;
        const { eventId } = match.params;
        const { pageNumber } = this.state;
        this.props.getUserInfo(this.props.userId);
        this.props.getUserProfileInfo(this.props.userId);
        getEvent(eventId);
        getVotes(eventId);
        getExhibitors(eventId, this.state.searchString, this.state.interestsShow, this.state.filterState, this.state.filter_prod, this.state.savedBookmark);
        getExhibitorFilterProducts(eventId);
        getEvent(eventId);
        getVotes(eventId);
        getExhibitors(eventId, this.state.searchString, this.state.interestsShow, this.state.filterState, this.state.filter_prod, this.state.savedBookmark);
        getExhibitorFilterProducts(eventId);
        this.setState({ activeTab: 0 })

    }

    componentWillReceiveProps(newProps) {
        const { userId } = newProps;
        const { eventId } = this.props.match.params;
        if (this.props.userId !== userId) {
            this.props.getUserInfo(userId);
            this.props.getUserProfileInfo(userId);
        }

        if (newProps.feedType !== this.props.feedType || newProps.entityId !== this.props.entityId) {
            const eventPagination = { start: 1, size: 50 };
            this.props.setEventPagination(eventPagination);

        }

        /* if (newProps.exhibitorsCollection!= null && newProps.exhibitorsCollection.length > 0 ){
            let exhibitors_Collection = this.state.exhibitors_Collection;
            for (let item of newProps.exhibitorsCollection){
                exhibitors_Collection.push(item);
                this.setState({exhibitors_Collection : exhibitors_Collection})
            }
        } */
        if (this.props.common.saveStatus === apiStatus.COMPLETED) {
            setTimeout(() => {
                this.setState({ voting_loading: false })
            }, 1000);
        }

        if (this.props.eventHallData != undefined && this.props.eventHallData == true) {
            this.setState({ hallboothError: true })
            setTimeout(() => {

                this.setState({ hallboothError: false })
            }, 3500);
        }

        if (this.props.eventHallBoothSuccess != undefined && this.props.eventHallBoothSuccess == true) {
            this.setState({ modalOpen2: false })
            this.props.getExhibitors(eventId, this.state.searchString, this.state.interestsShow, this.state.filterState, this.state.filter_prod, this.state.savedBookmark);
        }

        if (this.props.exibitorfiltersuccess == true) {
            this.setState({ show_filter_loader: true })
        }
        
        if (this.props.eventBookmarkSuccess != undefined && this.props.eventBookmarkSuccess == true) {
            this.props.getExhibitors(eventId, this.state.searchString, this.state.interestsShow, this.state.filterState, this.state.filter_prod, this.state.savedBookmark);
        }

        if (this.props.eventBookmarkFail == true) {
            this.setState({ bookmarkError: true })
            setTimeout(() => {
                this.setState({ bookmarkError: false })
            }, 3500);
        }

        if (!this.props.exibitorfiltersuccess) {
            setTimeout(() => {
                this.setState({ show_filter_loader: false })
            }, 500);
        }

    }

    refreshTagData = (flag) => {
        const { getEvent, getVotes, getExhibitors, match, getExhibitorFilterProducts } = this.props;
        const { eventId } = match.params;
        if (flag == true) {
            getExhibitors(eventId, this.state.searchString, this.state.interestsShow, this.state.filterState, this.state.filter_prod, this.state.savedBookmark);
        }
    }

    handleLoadMoreClick = () => {
        const { auth, eventPagination, setEventPagination, match, getExhibitors, getExhibitorFilterProducts } = this.props;
        const { eventId } = match.params;
        if (auth) {
            this.setState({ loading: true });

            eventPagination.start += 1;
            setEventPagination(eventPagination);
            getExhibitors(eventId, this.state.searchString, this.state.interestsShow, this.state.filterState, this.state.filter_prod, this.state.savedBookmark);
            setTimeout(() => {
                this.setState({ loading: false });
            }, 1000);
        } else {
           // this.props.showLoginModal(true);
            this.ShowLogin(true);
        }
    }


    componentDidUpdate(prevProps) {

        if (this.props.match.params.eventId !== prevProps.match.params.eventId) {
            const eventId = this.props.match.params.eventId
            window.scrollTo({ top: 0, left: 0 });
            this.props.resetEvent();
            this.props.getEvent(eventId);
            this.props.getVotes(eventId);
            this.props.getExhibitors(eventId, this.state.searchString, this.state.interestsShow, this.state.filterState, this.state.filter_prod, this.state.savedBookmark);
            this.props.getExhibitorFilterProducts(eventId);
            this.setState({ activeTab: 0 })
        }
        if (this.props.eventDeleted) {
            this.props.history.push(`/networkFeed/user/${this.props.auth.id}`);
            this.props.resetEvent();
            this.setState({ activeTab: 0 })
        }
    }

    handleTabChange = (e, { activeIndex }) => {
        this.setState({ activeTab: activeIndex })
    }

    getListedContacts = (companyId) => {
        const data = this.props.employeesCollection && this.props.employeesCollection.find(item => item.company_Id === companyId)
        return data ? data.count : '';
    }

    updateVote = (vote) => {
        if (!this.props.auth) {
            // this.props.history.push("/signup");
            //this.props.showLoginModal(true)
            this.ShowLogin(true);
            return;
        }
        this.setState({ voting_loading: true })
        this.props.updateVote(this.props.eventDetails.id, vote)

    }

    getExhibitors(filter) {
        this.setState({ exhibitorFilter: filter === "relevant" ? "relevant" : "All" });
        this.props.getExhibitors(this.props.eventDetails.id, filter === "relevant", this.state.interestsShow);
    }

    setAttendeeFilter = (filter) => {
        this.setState({ attendeeFilter: filter.value })
    }

    getAttendees() {
        const { attendees } = this.props;
        const { attendeeFilter } = this.state;
        if (!attendeeFilter) {
            return attendees.filter(item => item.vote === eventVotes.GOING);
        }
        switch (attendeeFilter) {
            case 'Attending':
                return attendees.filter(item => item.vote === eventVotes.GOING);

            case 'Interested':
                return attendees.filter(item => item.vote === eventVotes.INTERESTED);

            case 'Not Sure':
                return attendees.filter(item => item.vote === eventVotes.NOTSURE);

            case 'Relevant to Me':
                if (!this.props.auth) {
                    this.props.history.push("/?login=true");
                    return;
                }
                this.props.getVotes(this.props.eventDetails.id, attendeeFilter === 'Relevant to Me');
                return [];

            case 'Show All':
                return attendees;
            default:
                return attendees;
        }
    }

    editEvent(id) {
        this.props.history.push(`/edit-event/${id}`);
    }

    toggleshareEventPopup = (e) => {
        if (e !== undefined) {
            e.stopPropagation();
        }
        const { auth, history, showLoginModal } = this.props;
        if (!auth) {
            // showLoginModal(true);
            this.ShowLogin(true);
        } else {
            this.setState({ sharePostPopup: !this.state.sharePostPopup })
        }
    }

    hideShareEvent = () => {
        this.setState({ sharePostPopup: false })
    }

    openExibitorFilter = () => {
        const { auth, history, showLoginModal, match, getExhibitorFilterProducts } = this.props;
        const { eventId } = match.params;
        // getExhibitorFilterProducts(id);
        this.setState({ modalOpen: true })

    }

    applyPopupFilter = () => {
        const { match, getExhibitors } = this.props;
        const { eventId } = match.params;
        const eventPagination = { start: 1, size: 50 };
        this.props.setEventPagination(eventPagination);
        setTimeout(() => {
            getExhibitors(eventId, this.state.searchString, this.state.interestsShow, this.state.filterState, this.state.filter_prod, this.state.savedBookmark);
            this.setState({
                modalOpen: false
            });
        }, 200);
    }

    handleFilterPopupClose = () => {
        const { match, getExhibitors } = this.props;
        const { eventId } = match.params;
        this.setState({ modalOpen: false, filterState: false, filter_prod: [] })
        const eventPagination = { start: 1, size: 50 };
        this.props.setEventPagination(eventPagination);
        setTimeout(() => {
            getExhibitors(eventId, this.state.searchString, this.state.interestsShow, this.state.filterState, this.state.filter_prod, this.state.savedBookmark);
        }, 200);
    }

    handleOpen1 = () => {
        this.setState({ modalOpen1: true })
    }

    handleClose1 = () => {
        this.setState({ modalOpen1: false })
    }

    openHallBoothPopup = (row_data) => {
        this.setState({
            modalOpen2: true, HBformData: {
                ...this.state.HBformData, exhibitor_id: row_data.id, hallNumber: row_data.hallNumber, boothNumber: row_data.boothNumber
            }
        })
    }

    addUpdateHallBooth = () => {
        const { auth, showLoginModal, addHallBooth, match } = this.props;
        if (!auth) {
            //showLoginModal(true);
            this.ShowLogin(true);
        } else {
            const { eventId } = match.params;
            const { HBformData } = this.state;

            let object = {
                exhibitorId: HBformData.exhibitor_id,
                hallNumber: HBformData.hallnumber,
                boothNumber: HBformData.boothnumber,
            }
            this.props.addHallBooth(eventId, object);
        }
    }

    CloseHallBoothPopup = () => {
        this.setState({ modalOpen2: false })
    }

    enableHallBoothButton = () => {
        const {
            hallnumber,
            boothnumber
        } = this.state.HBformData;
        return (hallnumber !== "" && hallnumber !== undefined) ||
            (boothnumber !== "" && boothnumber != undefined)
    }

    handleHBInputChange = (event) => {
        let current_value = event.target.value;
        this.setState({
            HBformData:
                { ...this.state.HBformData, [event.target.name]: current_value }
        })
    }

    handleProdSearchValue = (event) => {
        let current_value = event.target.value;
        this.setState({
            search_by_product: current_value
        })
    }

    handleBookMarkSave = (row_data) => {
        const { auth, showLoginModal, addBookmark, removeBookmark, match } = this.props;
        if (!auth) {
            //showLoginModal(true);
            this.ShowLogin(true);
        } else {
            const { eventId } = match.params;
            if (row_data.bookmarked == undefined || row_data.bookmarked == false) {
                addBookmark(eventId, row_data.id)
            } else {
                removeBookmark(eventId, row_data.id)
            }
        }
    }

    filterBookmarked() {
        const { auth, showLoginModal, addBookmark, removeBookmark, match } = this.props;
        if (!auth) {
            //showLoginModal(true);
            this.ShowLogin(true);
        } else {
            let savedB = this.state.savedBookmark;
            if (savedB == 'false') {
                this.setState(
                    { savedBookmark: 'true' }
                );
            } else {
                this.setState(
                    { savedBookmark: 'false' }
                );
            }

            const { match, getExhibitors } = this.props;
            const { eventId } = match.params;
            const eventPagination = { start: 1, size: 50 };
            this.props.setEventPagination(eventPagination);
            setTimeout(() => {
                getExhibitors(eventId, this.state.searchString, this.state.interestsShow, this.state.filterState, this.state.filter_prod, this.state.savedBookmark);
                this.setState({
                    modalOpen: false
                });
            }, 200);
        }

    }

    interestsToggle = () => {

        const { auth, showLoginModal, eventPagination, setEventPagination, match, getExhibitors, getExhibitorFilterProducts } = this.props;
        const { eventId } = match.params;
        if (!auth) {
            //showLoginModal(true);
            this.ShowLogin(true);
        } else {
            this.setState(
                { interestsShow: !this.state.interestsShow }
            );

            setTimeout(() => {
                eventPagination.start = 0;
                setEventPagination(eventPagination);
                getExhibitors(eventId, this.state.searchString, this.state.interestsShow, this.state.filterState, this.state.filter_prod, this.state.savedBookmark);
            }, 200);
        }
    }

    renderExhibitorsProducts(exhibitors_data) {
        let prod_list = [];
        exhibitors_data.suppliedProduct && exhibitors_data.suppliedProduct.map(product_item =>
            prod_list.push(product_item.product_name)
        )

        if (prod_list.length > 0) {
            var prod_string = prod_list.join(', ')
            return (
                <ShowMoreLess content={prod_string} character={75}></ShowMoreLess>
            )
        } else {
            return '';
        }
    }

    renderExibitorProdfilter(product_data) {
        if (product_data != undefined) {
            return product_data.length > 0 && product_data.map((item, index) => {
                let checked = false;
                for (let i in this.state.filter_prod) {
                    if (this.state.filter_prod[i] == item.product_id) {
                        checked = true;
                    }
                }
                return (
                    <li>
                        <Checkbox label={item.product_name} onClick={(e, data) => this.setFilterProdItems(data, item)} checked={checked} />
                    </li>
                )
            })
        }
    }

    setFilterProdItems = (data, row) => {
        let prod_filter = this.state.filter_prod;
        let filter = false;
        if (data.checked) {
            prod_filter.push(row.product_id)
            filter = true;
        } else {
            const index = prod_filter.indexOf(row.product_id);
            if (index > -1) {
                prod_filter.splice(index, 1);
            }

            if (prod_filter.length == 0) {
                filter = false;
            }
        }

        this.setState(
            { filter_prod: prod_filter, filterState: filter }
        );

    }

    searchExhibitors = (event) => {
        const { getExhibitors, match, getExhibitorFilterProducts } = this.props
        const { eventId } = match.params;
        const eventPagination = { start: 1, size: 50 };
        this.props.setEventPagination(eventPagination);
        const exhibitionSearch = event.target.value;
        this.setState({ searchString: exhibitionSearch })

        setTimeout(() => {
            if (exhibitionSearch != '') {
                if (exhibitionSearch.length > 0) {
                    getExhibitors(eventId, exhibitionSearch, this.state.interestsShow, this.state.filterState, this.state.filter_prod, this.state.savedBookmark);
                }
            } else {
                getExhibitors(eventId, exhibitionSearch, this.state.interestsShow, this.state.filterState, this.state.filter_prod, this.state.savedBookmark);
            }
        }, 200);
    }

    searchFilterProducts = (event) => {
        const { match, getExhibitorFilterProducts } = this.props
        const { eventId } = match.params;
        const productfilterSearch = event.target.value;
        this.setState({ prodfiltersearchString: productfilterSearch })
        setTimeout(() => {
            if (productfilterSearch != '') {
                if (productfilterSearch.length > 2) {
                    getExhibitorFilterProducts(eventId, productfilterSearch);
                }
            } else {
                getExhibitorFilterProducts(eventId,);
            }
        }, 200);
    }

    render_slider = () => {
        const { eventDetails } = this.props;
        const { image, eventName } = eventDetails || {};
        const default_image = this.state.src;
        if (image != undefined) {
            if (Array.isArray(image) && image.length > 0) {
                return image.length > 0 && image.map((item, index) => {
                    return (
                        <div>
                            <img src={item} alt={eventName} title={eventName} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = default_image;
                            }} />
                        </div>
                    )
                })
            } else if (!Array.isArray(image)) {
                return (
                    <div>
                        <img src={image} alt={eventName} title={eventName} onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = default_image;
                        }} />
                    </div>
                )
            } else {
                return (
                    <div>
                        <img src={default_image} alt={eventName} title={eventName} />
                    </div>
                )
            }
        } else {
            return (
                <div>
                    <img src={default_image} alt={eventName} title={eventName} />
                </div>
            )
        }
    }
    
    ShowLogin = (flag) => {
        if (flag == true) {
            this.setState({ showLoginAlert: true })
        }
    }

    setStateLoginClose = () => {
        this.setState({ showLoginAlert: false })
    }

    render() {
        const { name, eventId } = this.props.match.params;
        const { eventDetails } = this.props;

        // if (!eventDetails) return null;

        if (!name && eventDetails) {
            this.props.history.push(`/events/${replaceSpaceInName(eventDetails.eventName)}/${eventId}`);
            return null;
        }
        const settings = {
            dots: false,
            infinite: false,
            autoplay: true,
            autoplaySpeed: 2800,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };

        const { activeTab, loading, voting_loading, activeIndex, hallboothError, bookmarkError, HBformData, filter_prod, savedBookmark, show_filter_loader, search_by_product } = this.state;
        const { exhibitorsCollection, attendees, tagProductsAndCompanies, auth, deleteEvent, votes, addExhibitors, setSaveStatus, eventPagination, exibitor_filter_prod, exibitorsearchsuccess } = this.props;
        const { eventName, location, image, startDate, endDate, details, id, createdBy, exhibitorsCount, eventType, organiserContactDetail, contactSupportInfo, organiserEmail, organiserName, contactSupportInfoTwo } = eventDetails || {};
        var { onlineEventURL, organiserWebsiteLink, eventLocationURL } = eventDetails || {};
        const show_img = image || this.state.src
        var userName = this.props.userName;
        var userId = this.props.userId;
        if (auth.id == createdBy) {
            userName = auth.displayName;
            userId = auth.id;
        } else {
            userId = createdBy;
        }

        const eventStartDate = (isNaN(startDate)) ? startDate : formatIsoMiliseconds(+startDate);
        const eventStartDay = (isNaN(startDate)) ? getDay(startDate) : getDay(+startDate);
        const eventEndDate = (isNaN(endDate)) ? endDate : formatIsoMiliseconds(+endDate);
        const eventEndDay = (isNaN(endDate)) ? getDay(endDate) : getDay(+endDate);
        var exhibitorCollectionLength = (exhibitorsCollection != undefined && exhibitorsCollection.length) ? exhibitorsCollection.length : 0;
        if (onlineEventURL != undefined && onlineEventURL != '') {
            if (onlineEventURL.indexOf("http://") == -1 && onlineEventURL.indexOf("https://") == -1) {
                onlineEventURL = "http://" + onlineEventURL;
            }
        }

        if (organiserWebsiteLink != undefined && organiserWebsiteLink != '') {
            if (organiserWebsiteLink.indexOf("http://") == -1 && organiserWebsiteLink.indexOf("https://") == -1) {
                organiserWebsiteLink = "http://" + organiserWebsiteLink;
            }
        }

        if (eventLocationURL != undefined && eventLocationURL != '') {
            if (eventLocationURL.indexOf("http://") == -1 && eventLocationURL.indexOf("https://") == -1) {
                eventLocationURL = "http://" + eventLocationURL;
            }
        }

        let votesInterested = [], votesGoing = [], votesnotSure = [];
        let userVote = '';
        if (votes) {
            Object.keys(votes).forEach(key => {
                switch (votes[key]) {
                    case eventVotes.INTERESTED:
                        votesInterested.push(key)
                        break;
                    case eventVotes.GOING:
                        votesGoing.push(key)
                        break;
                    case eventVotes.NOTSURE:
                        votesnotSure.push(key)
                        break;

                    default:
                        break;
                }
            })
        }
        if (votesInterested.includes(auth.id)) {
            userVote = "Interested";
        } else if (votesGoing.includes(auth.id)) {
            userVote = "Going";
        } else if (votesnotSure.includes(auth.id)) {
            userVote = "NotSure";
        }
        const panes = [
            // { menuItem: 'About', render: () => <Tab.Pane attached={false}><pre className="eventDetails" dangerouslySetInnerHTML={{ __html: details }}></pre></Tab.Pane> },
            // { menuItem: 'Discussion', render: () => <Tab.Pane attached={false}></Tab.Pane> },
            {
                menuItem: 'About', render: () => <Tab.Pane attached={false} >
                    <div className='event-info'>
                        <div className='about-event'>
                            {/* <ShowMoreText
                                lines={4}
                                children={'React Node'}
                                more="Show more"
                                less="Show less"
                                className="content-css feed-text-only"
                                anchorClass="my-anchor-css-class"
                                onClick={this.executeOnClick}
                                expanded={false}
                                width={800}
                                truncatedEndingComponent={"... "} >
                                <div className="art-body-text" dangerouslySetInnerHTML={{ __html: details }}></div>
                            </ShowMoreText> */}
                            <ShowMoreLess content={details}  ></ShowMoreLess>
                        </div>
                        {organiserWebsiteLink && (<div className='detailsrow'>
                            <b className='h3-title'>Event Organiser</b>
                            <a href={organiserWebsiteLink} target='_blank'>{organiserWebsiteLink}</a>
                        </div>)}
                        {/*    {organiserContactDetail || contactSupportInfo ? (<div className='detailsrow'>
                            <b className='h3-title'>Contact details</b>
                            {organiserContactDetail && (<p>Contact person name ({organiserContactDetail})</p>)}
                            <p>{contactSupportInfo}</p>
                        </div>) : ''} */}
                        {organiserName ? (
                            <div className='detailsrow'>
                                <b className='h3-title'>Contact Details</b> {organiserName && (
                                    <b> <p>  {organiserName}</p> </b>)}
                                {organiserEmail && (<p> <a href={'mailto:' + organiserEmail} target='_blank'>{organiserEmail}</a></p>)}
                                <p>
                                    {contactSupportInfo && (<span> {contactSupportInfo} </span>)}
                                    {contactSupportInfo && contactSupportInfoTwo && (<span>,</span>)}
                                    {contactSupportInfoTwo && (<span> {contactSupportInfoTwo} </span>)}
                                </p>


                            </div>
                        ) : ''}

                        <div className='detailsrow'>
                            <b className='h3-title'>Any help?</b>
                            <p>Call us at +91 96508 40033 or email us at <a href={'mailto:help@mappes.io'} target='_blank'>help@mappes.io</a> </p>
                        </div>
                    </div>
                </Tab.Pane>
            },
            {
                menuItem: (
                    <Menu.Item key='messages' className={'tabsection'} disabled={!exhibitorsCollection}>
                        Exhibitors & products
                        <Label className={!exhibitorsCollection ? 'disabled' : ''}>
                            {this.state.searchString != '' ? <span> {exhibitorCollectionLength} </span> : <span> {exhibitorsCount}</span>}
                        </Label>
                    </Menu.Item>
                ), render: () => <Tab.Pane attached={false} >
                    {
                        !exhibitorsCollection && <Loader active />
                    }
                    <div className='header-row'>
                        <Row>
                            <Col sm='7'>
                                <h2>Exhibitors & products</h2>
                                <p>
                                    List of companies that are likely to showcase their products at this event
                                </p>
                            </Col>
                            <Col className='showinterest-col' sm='5'>
                                <Button onClick={() => this.interestsToggle()}>
                                    {
                                        !this.state.interestsShow ? <span><SvgIcon name='eye-alt' viewbox="0 0 26 18" /> Show only my interests</span> : <span><SvgIcon name='eye-off' viewbox="0 0 26 22" /> Hide my interests</span>
                                    }
                                </Button>
                            </Col>
                        </Row>
                        {bookmarkError == true && (
                            <div className='mt-2'>
                                <Row>
                                    <Col>
                                        <Message className="reduce-msg-padding" compact visible error content={`Unable to save bookmark at the moment, please try again  `} />
                                    </Col>
                                </Row>
                            </div>)
                        }
                        <br />
                        <div className='fiter-section'>
                            <Input icon='search' placeholder='Search company' onKeyUp={this.searchExhibitors} />
                            {savedBookmark === "true" ? (
                                <Button className='save-btn saved-btn' onClick={() => this.filterBookmarked()}><SvgIcon name='save-icon-filled' viewbox="0 0 16 20" /> Saved</Button>
                            ) : (
                                <Button className='save-btn' onClick={() => this.filterBookmarked()} ><SvgIcon name='save-icon' viewbox='0 0 16 20' /> Save</Button>
                            )}

                            <Modal
                                open={this.state.modalOpen}
                                className='eventdtl-productfilter-modal'
                                size='small'
                                trigger={<span>
                                    {filter_prod.length > 0 ?
                                        (<Button className='filtered-btn' name='Filtered' onClick={() => this.openExibitorFilter()}>
                                            <div className='ui label mr-1'> {filter_prod.length} </div> Filtered
                                        </Button>)
                                        :
                                        (<Button name='filter_by' onClick={() => this.openExibitorFilter()}>
                                            {filter_prod.length > 0 ? <span> <div className='ui label mr-1'> {filter_prod.length} </div> Filtered</span> : <> <SvgIcon name='filter' viewbox='0 0 24 24' />  Filter by</>}
                                        </Button>)} </span>
                                }
                            >
                                <div className='header'>
                                    <div className='header-left'>
                                        <h2>Filter by:</h2>
                                        <div className='btn-list'>
                                            <button className='active'>Products</button>
                                            {
                                                show_filter_loader && <Loader className={"product-filter-loader mt-2"} active inline center />
                                            }
                                            {/*  <button>Hall / Booth</button> */}
                                        </div>
                                    </div>
                                    <Button onClick={this.handleFilterPopupClose} className='close-button'><SvgIcon name='close-alt' viewbox="0 0 15 15.001" /></Button>
                                </div>
                                <Modal.Content>
                                    <div className='product-filter'>
                                        <Input icon='search' placeholder='Search by product' onKeyUp={this.searchFilterProducts} name="hallnumber" id="hallnumber" onChange={this.handleProdSearchValue} defaultValue={search_by_product} />

                                        <ul>
                                            {this.renderExibitorProdfilter(exibitor_filter_prod)}

                                        </ul>
                                    </div>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button className='default-btn' onClick={this.handleFilterPopupClose}>
                                        Clear all
                                    </Button>
                                    <Button color='primary' onClick={this.applyPopupFilter}>
                                        Apply
                                    </Button>
                                </Modal.Actions>
                            </Modal>
                        </div>
                    </div>
                    <MediaQuery maxWidth={991}>
                        <div className='tltle-secondary'>
                            Companies & their products
                        </div>
                    </MediaQuery>
                    {exhibitorsCollection!= undefined && exhibitorsCollection && (
                        <InfiniteScroll
                            dataLength={exhibitorsCollection.length}
                            next={this.handleLoadMoreClick}
                            hasMore={exhibitorsCollection.length < eventPagination.total}
                            scrollableTarget="sidebar-pushable"
                            loader={<Loader active inline center />}
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    {
                                        // !auth && <LoginButton />
                                    }
                                </p>
                            }
                        >
                            <span>
                                <MediaQuery minWidth={992}>
                                    <Table padded basic='very' striped unstackable className="exhibitor-table">
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell className="color-light-blue exhibitor-table-firsc-col"><b>Company & their products</b></Table.HeaderCell>
                                                <Table.HeaderCell className="color-light-blue" textAlign='center'><b>#Booth</b></Table.HeaderCell>
                                                <Table.HeaderCell className="color-light-blue" textAlign='center'><b>Country</b></Table.HeaderCell>
                                                <Table.HeaderCell className="color-light-blue" textAlign='center'><b>#Contacts</b></Table.HeaderCell>
                                                <Table.HeaderCell className="color-light-blue" textAlign='center'><b>Save</b></Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>

                                            {
                                                exhibitorsCollection && exhibitorsCollection.map(exhibitor =>
                                                    <Table.Row>
                                                        <Table.Cell className='exhibitor-table-firsc-col' data-label="Company & their products">
                                                            <div className='companyproduct-col'>
                                                                <div className='images'>
                                                                    {exhibitor.logo != undefined && exhibitor.logo != '' ? <Image className="avatar-42" avatar src={exhibitor.logo} /> : ''}

                                                                </div>
                                                                <div>
                                                                    <b><Link to={`/company/${replaceSpaceInName(exhibitor.name)}/${exhibitor.id}`}>{exhibitor.name}</Link></b>
                                                                    <p>
                                                                        {this.renderExhibitorsProducts(exhibitor)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Table.Cell>
                                                        <Table.Cell textAlign='center' data-label="#Booth">
                                                            {auth &&
                                                                <Modal
                                                                    open={this.state.modalOpen2}
                                                                    className='eventdtl-productfilter-modal'
                                                                    size='small'
                                                                    trigger={<Button className='edit-booth' onClick={() => this.openHallBoothPopup(exhibitor)}><SvgIcon name='pencil' viewbox="0 0 16 15" /></Button>}>
                                                                    <div className='header'>
                                                                        <h2 className='mb-0'>Add hall & booth numbers</h2>
                                                                        <Button onClick={this.CloseHallBoothPopup} className='close-button'><SvgIcon name='close-alt' viewbox="0 0 15 15.001" /></Button>
                                                                    </div>
                                                                    <Modal.Content>
                                                                        <div className='boothnumer-inner'>
                                                                            <div>
                                                                                <label>Hall number</label>
                                                                                <Input placeholder='Enter Hall number' name="hallnumber" id="hallnumber" onChange={this.handleHBInputChange} defaultValue={HBformData.hallNumber} />
                                                                            </div>
                                                                            <div className='mt-3'>
                                                                                <label>Booth number</label>
                                                                                <Input placeholder='Enter Booth number' name="boothnumber" id="boothnumber" onChange={this.handleHBInputChange} defaultValue={HBformData.boothNumber} />
                                                                            </div>
                                                                        </div>
                                                                    </Modal.Content>
                                                                    {hallboothError == true && (
                                                                        <div className='mt-2'>
                                                                            <Row>
                                                                                <Col>
                                                                                    <Message className="reduce-msg-padding" compact visible error content={`Unable to save hall & booth at the moment, please try again  `} />
                                                                                </Col>
                                                                            </Row>
                                                                        </div>)
                                                                    }
                                                                    <Modal.Actions textAlign="center">
                                                                        <Button className='px-4' basic color='primary'
                                                                            onClick={this.addUpdateHallBooth}
                                                                            loading={this.props.common.saveStatus === apiStatus.IN_PROGRESS}
                                                                            disabled={!this.enableHallBoothButton()}>
                                                                            Save
                                                                        </Button>
                                                                    </Modal.Actions>
                                                                </Modal>
                                                            }
                                                            {exhibitor.hallNumber && <div><label className='boothhall-label'>Hall  {exhibitor.hallNumber}
                                                                {exhibitor.hallNumber && exhibitor.boothNumber && <span> , </span>}
                                                            </label></div>}
                                                            {exhibitor.boothNumber && <div><label className='boothhall-label'>Booth  {exhibitor.boothNumber} </label></div>}
                                                        </Table.Cell>
                                                        <Table.Cell textAlign='center' data-label="Country"><label>{exhibitor.country}</label></Table.Cell>
                                                        <Table.Cell textAlign='center' data-label="#Contacts"><b className='contact-label'>{exhibitor.count != '0' && exhibitor.count != 0 && exhibitor.count}</b></Table.Cell>
                                                        {/* <Table.Cell textAlign='center' data-label="Save"><SvgIcon className='bookmark-icon-filled' name='' viewbox='' /></Table.Cell> */}
                                                        <Table.Cell textAlign='center'><SvgIcon onClick={() => this.handleBookMarkSave(exhibitor)} className={exhibitor.bookmarked === true ? "bookmark-icon filled" : "bookmark-icon"} name={exhibitor.bookmarked === true ? "save-icon-filled" : "save-icon"} viewbox={exhibitor.bookmarked === true ? "0 0 16 20" : "0 0 16 20"} /></Table.Cell>
                                                    </Table.Row>
                                                )
                                            }
                                            {/*   {auth &&
                                    <Table.Row>
                                        <Table.Cell className='loadmore-cell' colSpan='5'>
                                            <Button className="load_more" onClick={this.handleLoadMoreClick} loading={loading} >
                                                Show more
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                } */}
                                        </Table.Body>

                                    </Table>
                                </MediaQuery>
                                <MediaQuery maxWidth={991}>
                                    {
                                        exhibitorsCollection && exhibitorsCollection.map(exhibitor =>
                                            <div className='mobileproduct-list'>
                                                <div className='companyproduct-col'>
                                                    <div>
                                                        <b><Link to={`/company/${replaceSpaceInName(exhibitor.name)}/${exhibitor.id}`}>{exhibitor.name}</Link></b>
                                                        <p>
                                                            {this.renderExhibitorsProducts(exhibitor)}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='vanue-dtl'>
                                                    {exhibitor.boothNumber} {exhibitor.boothNumber && '•'} {exhibitor.country}
                                                </div>
                                                <div className='mobileevent-bookmark'>
                                                    <SvgIcon onClick={() => this.handleBookMarkSave(exhibitor)} className={exhibitor.bookmarked === true ? "bookmark-icon filled" : "bookmark-icon"} name={exhibitor.bookmarked === true ? "save-icon-filled" : "save-icon"} viewbox={exhibitor.bookmarked === true ? "0 0 16 20" : "0 0 16 20"} />
                                                </div>
                                            </div>
                                        )
                                    }
                                </MediaQuery>
                            </span>
                        </InfiniteScroll>
                    )}
                    {
                        exhibitorsCollection.length == 0 && !exibitorsearchsuccess && (
                            <Table padded basic='very' striped unstackable className="exhibitor-table">
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell className=""><b>No exhibitor available</b></Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        )
                    }

                    {exibitorsearchsuccess && <Loader className={"product-filter-loader mt-2"} active inline center />}



                </Tab.Pane>
            },
            /*              {
                             menuItem: 'Attendees', render: () =>
                                 <Tab.Pane attached={false}>
                                     {
                                         auth && !attendees && <Loader active />
                                     }
                                     <Dropdown icon='filter' className='icon filterBtn'>
                                         <Dropdown.Menu className='left'>
                                             {filterOptionAttendees.map((option) => (
                                                 <Dropdown.Item
                                                     className={option.class}
                                                     key={option.value}
                                                     onClick={() => this.setAttendeeFilter(option)}
                                                     {...option}
                                                     active={this.state.attendeeFilter === option.value}
                                                 />
                                             ))}
                                         </Dropdown.Menu>
                                     </Dropdown>
                                     <Table padded basic='very' striped unstackable className="mT-0">
                                         <Table.Header>
                                             <Table.Row>
                                                 <Table.HeaderCell className="color-light-blue">Name</Table.HeaderCell>
                                                 <Table.HeaderCell className="color-light-blue">Company</Table.HeaderCell>
                                                 <Table.HeaderCell textAlign='center' className="color-light-blue">Send Message</Table.HeaderCell>
                                             </Table.Row>
                                         </Table.Header>
                                         <Table.Body>
                                             {
                                                 attendees && this.getAttendees().map(attendee =>
                                                     <Table.Row>
                                                         <Table.Cell><Link to={`/networkFeed/user/${attendee.id}`}>{attendee.firstName} {attendee.lastName}</Link></Table.Cell>
                                                         <Table.Cell>
                                                             {
                                                                 attendee.company_id ?
                                                                     <Link to={`/company/${replaceSpaceInName(attendee.company)}/${attendee.company_id}`}>{attendee.company}</Link>
                                                                     :
                                                                     attendee.company
            
                                                             }
                                                         </Table.Cell>
                                                         <Table.Cell textAlign='center'>
                                                             <Message auth={auth} userId={attendee.id} userName={`${attendee.firstName} ${attendee.lastName}`} />
                                                         </Table.Cell>
                                                     </Table.Row>
                                                 )}
            
                                             {
                                                 !auth &&
                                                 <Table.Row>
                                                     <Table.Cell></Table.Cell>
            
                                                     <Table.Cell textAlign='center'>
                                                         <span> Please  &nbsp;<Button className="ui green button" onClick={() => this.props.showLoginModal(true)}>Login</Button> to see the Attendees </span>
                                                     </Table.Cell>
                                                     <Table.Cell></Table.Cell>
            
                                                 </Table.Row>
                                             }
            
                                         </Table.Body>
                                     </Table>
                                 </Tab.Pane>
                         } */
        ]

        return (
            <>
                {
                    eventDetails &&
                    <SEO robots="index, follow"
                        canonicalUrl={`/events/${replaceSpaceInName(eventDetails.eventName)}/${this.props.match.params.eventId}`}
                        alternateLink={`/events/${replaceSpaceInName(eventDetails.eventName)}/${this.props.match.params.eventId}`}
                        pageType={'event'}
                        pageContent={eventDetails}
                    />
                }

                <Navbar {...this.props}>
                    <div className="primary-background">
                        <Container className="body-background palm-nudge-sides eventdtl-body">
                            <Grid padded stackable>
                                <MediaQuery minWidth={768}>
                                    <Grid.Column className="left-content">
                                        <LeftSidebar />
                                    </Grid.Column>
                                </MediaQuery>
                                <Grid.Column className="main-content-area eventdtl-main-area">
                                    <div className="main-content">
                                        <div className="main-content_inner">
                                            <Grid padded>
                                                <Grid.Row className='pt-0'>
                                                    <Grid.Column width={16} className='eventdetails-wrapper'>
                                                        {
                                                            !eventDetails &&
                                                            <Dimmer active inverted>
                                                                <Loader>Loading</Loader>
                                                            </Dimmer>
                                                        }
                                                        <div className='event-upper-card'>
                                                            <div className="event-img">
                                                                <Slider {...settings}>
                                                                    {this.render_slider()}
                                                                    {/*  <div>
                                                                        <img src={show_img} alt={eventName} title={eventName} />
                                                                    </div>
                                                                    <div>
                                                                        <img src={show_img} alt={eventName} title={eventName} />
                                                                    </div>
                                                                    <div>
                                                                        <img src={show_img} alt={eventName} title={eventName} />
                                                                    </div> */}
                                                                </Slider>
                                                            </div>
                                                            <div className='event-dtl'>
                                                                <div className="event-matadata">
                                                                    <div className="event-name">
                                                                        <h1 alt={eventName} title={eventName} className="name">{eventName}</h1>
                                                                        <span className="host">Public Event created by <b><Link to={`/networkFeed/user/${userId}`}>{userName}</Link></b></span>
                                                                    </div>
                                                                    <div className="event-right-more">
                                                                        <MappesDropdown overlayClass='' width={180} buttonLabel={<SvgIcon name='ellipsis-vertical' viewbox='0 0 10.125 38.875' />}>
                                                                        <List>
                                                                                <TagModal searchType="product" eventId={id} tagProductsAndCompanies={tagProductsAndCompanies} addExhibitors={addExhibitors} saveStatus={this.props.common.saveStatus} setSaveStatus={setSaveStatus} ShowLogin={this.ShowLogin} refreshTagData={this.refreshTagData} />
                                                                                <TagModal searchType="company" eventId={id} tagProductsAndCompanies={tagProductsAndCompanies} addExhibitors={addExhibitors} saveStatus={this.props.common.saveStatus} setSaveStatus={setSaveStatus} ShowLogin={this.ShowLogin} />
                                                                                {/*  {auth && eventDetails && eventDetails.createdBy === auth.id &&
                                                                                <Dropdown.Item className="dropDownThreeDots" text='Edit Event' onClick={() => this.editEvent(id)} />
                                                                            } */}
                                                                                {auth && eventDetails && eventDetails.createdBy === auth.id &&
                                                                                    <>
                                                                                        <List.Item className="dropDownThreeDots" onClick={() => this.editEvent(id)}>Edit Event</List.Item>
                                                                                        <List.Item className="dropDownThreeDots" onClick={() => deleteEvent(id)}>Remove this Event</List.Item>
                                                                                    </>
                                                                                }
                                                                                <Popup
                                                                                    basic
                                                                                    trigger={<List.Item className="dropDownThreeDots" onClick={this.toggleshareEventPopup}>Share This Event</List.Item>}
                                                                                    on="click"
                                                                                    flowing
                                                                                    className="sharepost-popup-wrapper"
                                                                                    open={this.state.sharePostPopup}
                                                                                    hideOnScroll={true}
                                                                                    position='top right'
                                                                                    content={
                                                                                        <SharePost
                                                                                            type='event'
                                                                                            postId={id}
                                                                                            postTitle={eventName}
                                                                                            closePopup={this.toggleshareEventPopup}
                                                                                            hideShareEvent={() => this.hideShareEvent()}
                                                                                            userName={userName} />
                                                                                    }
                                                                                />
                                                                            </List>
                                                                        </MappesDropdown>
                                                                       
                                                                    </div>
                                                                </div>
                                                                <div className='event-datelocation'>
                                                                    <div className='time-col'>
                                                                        <div className='icon-col'>
                                                                            <SvgIcon name='calendar-icon' viewbox='0 0 17 18.667' />
                                                                        </div>
                                                                        <div className='time-list'>
                                                                            <div>
                                                                                <label>Start From</label>
                                                                                <p>
                                                                                    {eventStartDay} | {eventStartDate}
                                                                                    {/* {eventStartDate.split(',')[0]} */}
                                                                                </p>
                                                                            </div>
                                                                            <div className='pt-3'>
                                                                                <label>END at</label>
                                                                                <p>
                                                                                    {eventEndDay} | {eventEndDate}
                                                                                    {/* {eventEndDate.split(',')[0]} */}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='location-col'>
                                                                        {eventType == 'online' && (<span><div className='row-1'>
                                                                            <SvgIcon name='video-icon' viewbox='0 0 20.332 13.667' /> Online <a href={onlineEventURL} target='_blank' className='ml-1'>Open event link</a>
                                                                        </div> </span>)}
                                                                        <div className='row-2'>
                                                                            <div className='d-flex align-items-center mb-1'>
                                                                                {/* {eventType == 'offline' &&  (*/}<span><SvgIcon name='map-icon' viewbox='0 0 18 20' />

                                                                                    <div className="time-list">
                                                                                        <div>
                                                                                            <label className='mb-0'>Venue</label>
                                                                                        </div>
                                                                                    </div></span>{/* )} */}
                                                                                {eventType == 'online' &&
                                                                                    <div className='locaton-name pl-0'>
                                                                                        {location}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            {eventType == 'offline' &&
                                                                                <div className='locaton-name'>
                                                                                    {location}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        {eventType == 'offline' && (<div className='row-2 view-map'>
                                                                            {
                                                                                eventLocationURL != undefined && eventLocationURL != '' && <a href={eventLocationURL} target='_blank' className='mt-2'>View Map</a>
                                                                            }
                                                                        </div>)}
                                                                    </div>
                                                                </div>
                                                                <div className="event-option">
                                                                    <Modal
                                                                        open={this.state.modalOpen1}
                                                                        className='eventdtl-attendees-modal'
                                                                        size='small'
                                                                        trigger={<div onClick={this.handleOpen1} className='title'>{attendees ? attendees.length : 0} attendees</div>}
                                                                    >
                                                                        <div className='header'>
                                                                            <h2 className='mb-0'>List of attendees</h2>
                                                                            <p>Total {attendees ? attendees.length : 0} are attending this event</p>
                                                                            <Button onClick={this.handleClose1} className='close-button'><SvgIcon name='close-alt' viewbox="0 0 15 15.001" /></Button>
                                                                        </div>
                                                                        <Modal.Content>
                                                                            <div className='eventdtl-list'>
                                                                                <ul>
                                                                                    {attendees && this.getAttendees().map(attendee =>
                                                                                        <li>
                                                                                            <div className='avatar-img'> {attendee.image ? <Image className="avatar-42" avatar src={attendee.image} /> : ''}</div>
                                                                                            <div>
                                                                                                <h3><Link to={`/networkFeed/user/${attendee.id}`}>{attendee.firstName} {attendee.lastName}</Link></h3>
                                                                                                <p> {attendee.company_id ? <Link className="text-secondary link-color" to={`/company/${replaceSpaceInName(attendee.company)}/${attendee.company_id}`}>{attendee.company}</Link> : attendee.company} </p>
                                                                                            </div>
                                                                                        </li>
                                                                                    )}
                                                                                </ul>
                                                                            </div>
                                                                        </Modal.Content>
                                                                    </Modal>

                                                                    <ul>
                                                                        <li className={userVote == "Going" && 'btn-active'}>
                                                                            <Button className="btn-with-icon" size="small" onClick={() => this.updateVote(eventVotes.GOING)}>I'm Going </Button>
                                                                            {/* <span>{votesGoing && votesGoing.length > 0 ? votesGoing.length + ' People' : ' '}</span> */}
                                                                        </li>
                                                                        <li className={userVote == "NotSure" && 'nosure-button btn-active'}>
                                                                            <Button className="btn-with-icon" size="small" onClick={() => this.updateVote(eventVotes.NOTSURE)}>Not going</Button>
                                                                            {/* <span>{votesnotSure && votesnotSure.length > 0 ? votesnotSure.length + ' People' : ''}</span> */}
                                                                        </li>
                                                                        <li className={userVote == "Interested" && 'maybe-button btn-active'}>
                                                                            <Button className="btn-with-icon" size="small" onClick={() => this.updateVote(eventVotes.INTERESTED)}>May Be</Button>
                                                                            {/* <span>{votesInterested && votesInterested.length > 0 ? votesInterested.length + ' People' : ' '}</span> */}
                                                                        </li>
                                                                    </ul>
                                                                    {voting_loading && <Dimmer active inverted>
                                                                        <Loader>Loading</Loader>
                                                                    </Dimmer>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="event-bottom-details">
                                                            <Tab className="eventdetails-tab" menu={{ secondary: true, pointing: true }} panes={panes} activeIndex={activeTab} onTabChange={this.handleTabChange} />
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </div>
                                    </div>
                                </Grid.Column>

                                <RightSection entityType="user" entityId={auth ? auth.id : null} />
                            </Grid>
                        </Container>
                    </div>
                      <GuestUserLoginToast {...this.props} show={this.state.showLoginAlert} setStateLoginClose={() => this.setStateLoginClose() } /> 
                </Navbar>
            </>
        );
    }
}

function mapStateToProps({ events, user, auth, common }) {
    return {
        ...events,
        userName: user.userProfileInfo && `${user.userProfileInfo.firstName} ${user.userProfileInfo.lastName}`,
        userId: user.userProfileInfo && user.userProfileInfo.user_id,
        auth,
        common,
        saveStatus: common.saveStatus
    };
}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({ getUserProfileInfo, getUserInfo, ...eventActions, ...commonActions }, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(Event);