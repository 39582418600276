import React, { Component } from 'react';
import { Form, Button, Radio, Dropdown } from 'semantic-ui-react';
import Navbar from '../../common/layout/navbar/Navbar';
import { countryList } from '../../../common/countries';
import LeftSidebar from '../../common/LeftSidebar';
import RightSection from '../../common/RightSection'; 
import "./index.scss";

import ProfileCompletionImg from '../../../assets/images/profile-completion-img.svg';
import { SvgIcon } from '../../common';
import { connect } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../../../config/config';
import { bindActionCreators } from 'redux';

const options = [
    {
        key: 1,
        text: 'Automatic solar cells tabber stringer',
        value: 1
    },
    {
        key: 2,
        text: 'Automatic solar cells tabber stringer',
        value: 2
    },
    {
        key: 3,
        text: 'Automatic solar cells tabber stringer',
        value: 3
    },
]

class CountrySelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userProfileDetails: this.props.user.userProfileDetails,
        }
    }
    handleChange = (e, { value }) => {
        this.setState({ userProfileDetails: { ...this.state.userProfileDetails, country: value } })
    }
    saveDetails = async () => {
        await axios.put(`${BASE_URL}/api/v2/me`, { ...this.state.userProfileDetails }, { withCredentials: true });
        localStorage.setItem('countrySelectionTime', new Date().toLocaleDateString());
        this.props.onSubmitSuccessful();
    }
    render() {
        return (
            <div className="middle-section profilecompletion-section">
                <div className='countryselect-card'>
                    <div className='countryselect-card-inner'>
                        <h4>COMPLETE YOUR PROFILE</h4>
                        <h2>Which country are your from?</h2>
                        <p>We will be able to provide you with customized and relevant information if you provide us with your country relevant to your field</p>
                        <div className='mt-2'>
                            <Dropdown
                                className='country-dropdown'
                                search
                                fluid
                                selection
                                placeholder='Select your country'
                                options={countryList}
                                name="country"
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className='text-center mt-4'>
                            <Button primary className='btn-primary' onClick={this.saveDetails}>Save details</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CountrySelection;