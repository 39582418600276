import { useState, useEffect, useRef } from 'react';

import { Loader } from 'semantic-ui-react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import './PdfViewer.scss';
import SvgIcon from './svg-icon/svg-icon';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
   'pdfjs-dist/build/pdf.worker.min.js',
   import.meta.url,
).toString();

const options = {
   cMapUrl: '/cmaps/',
   standardFontDataUrl: '/standard_fonts/',
};

export function PdfViewer(props) {
   const [file, setFile] = useState(props.pdf);
   const [width, setWidth] = useState(630);
   const [numPages, setNumPages] = useState(1);
   const [pageNumber, setPageNumber] = useState(1);
   const [scale, setScale] = useState(props.zoom || 1);
   const documentRef = useRef();
   const [loader, setLoader] = useState(false);
   const [renderedPageNumber, setRenderedPageNumber] = useState(null);

   useEffect(() => {
      if (documentRef) {

         /*   documentRef.current.addEventListener('onScroll', function (e) {
              listenScrollEvent();
           }, false); */

      }
   }, [])


   function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      /*   setTimeout(() => {
           setLoader(false)
        }, 600); */
   }

   /*  const listenScrollEvent = () => {
       console.log('Scroll event detected!');
    } */
   const load_prev = (pageNum) => {
      if (pageNum > 1) {
         setPageNumber(pageNum - 1)
      }
   }

   const load_next = (pageNum) => {
      if (pageNum < numPages) {
         setPageNumber(pageNum + 1)
      }
   }

   const decrease_scale = (pageNum) => {
      if (scale != 0) {
         setScale(scale - 0.25)
      }
   }

   const increase_scale = (pageNum) => {
      setScale(scale + 0.25)
   }

   const isLoading = renderedPageNumber !== pageNumber;
   const random_id = () => {
      return Math.floor(Math.random() * 100);
   }
   
   return (
      <>
    
         <div className={"PDFView"} >
            <div className="PDFView__container">
               <div className="PDFView__container__document" >
                  <Document
                     file={file}
                     options={options}
                     onLoadSuccess={onDocumentLoadSuccess}
                     inputRef={documentRef}
                     renderMode="canvas"
                  >
                     {
                        isLoading && renderedPageNumber ?
                           <Page
                              key={`page_${random_id()}_${renderedPageNumber}`}
                              className="prevPage"
                              pageNumber={renderedPageNumber}
                              loading={<Loader>Please wait.!</Loader>}
                              width={width}
                           />
                           :
                           /* Array.apply(null, Array(numPages))
                                .map((x, i) => i + 1)
                                .map(page =>  */
                           <Page
                              className={`main_page_${pageNumber}`}
                              loading={<Loader>Please wait.!</Loader>}
                              key={`page_${random_id()}_${pageNumber}`}
                              pageNumber={pageNumber}
                              width={width}
                              scale={scale}
                              // onRenderSuccess={() => setRenderedPageNumber(pageNumber)}
                           // onScroll={() => listenScrollEvent.bind(this)}
                           />

                        /* )  */
                     }

                     {
                        !loader && (
                           <div className="page-controls">
                              <div className="page-controls-inner">
                                 {numPages > 1 && <button type="button" disabled={pageNumber <= 1} onClick={() => load_prev(pageNumber)}>‹</button>}
                                 <span> {pageNumber} of {numPages} Page </span>
                                 {numPages > 1 && <button type="button" onClick={() => load_next(pageNumber)}>›</button>} |
                                 <button className='zoom-btn' type="button" onClick={() => decrease_scale(pageNumber)}>-</button>
                                 <SvgIcon name='zoom-icom' viewbox="0 0 54 54" />
                                 <button className='zoom-btn' type="button"  onClick={() => increase_scale(pageNumber)}>+</button>
                              </div>
                           </div>
                        )
                     }
                  </Document>
               </div>
            </div>
         </div>
      </>
   );
}